import React, { useEffect, useState } from 'react';
import './indexpageCss/Indexnewbanner.css'
import { Link } from 'react-router-dom';




function Indexnewbanner() {

    const [serdta, setServdata]=useState([]);
useEffect(()=>{
    const servicedata = async()=>{
        try{
            const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-service');
            const data = await result.json();
            setServdata(data);
        }catch(error){
            console.log(error);
        }
    }
    servicedata();
}, []);





  return (
    <div className='newbanner' >
      <div className="banner-text">
        {/* <h1>your preferred offsite planner.</h1> */}
        <img src='image/text-head.png'/>
        <h3>As one of the fastest-growing corporate event planner in India, we proudly serve over 50+ multinational clients, ensuring their teams have unforgettable experiences. Our offsites are uniquely tailored, unplugged, and crafted to engage every participant, with something for everyone. We focus on creating highly customized retreats that foster connection and collaboration. Let us help you design an extraordinary experience for your team!</h3>
        <Link class="glow-on-hover" to='/corporate-event-services' >Explore Our Services</Link>
      </div>
  <div className="banner-round">
    <div className="carousel">
      <div className="carousel-control-button left">
        <input type="radio" name="carousel-control-input" />
      </div>
      <div className="carousel-control-button right">
        <input type="radio" name="carousel-control-input" defaultChecked />
      </div>

      <div className="carousel-rotation-direction">
        {/* <ul className="carousel-item-wrapper" style={{ '--_num-elements': 12 }}>
          
          <li className="carousel-item" style={{ '--_index': 1, '--_image-url': `url('../image/Conference-and-Seminar.jpg')` }}>
            <a href="#" target="_blank" rel="noopener noreferrer">Architecture Example 1</a>
          </li> */}
           <ul className="carousel-item-wrapper" style={{ '--_num-elements': 8 }}>
             
           {
  serdta
    .filter(serv => serv.status === 1) // Filter items with status === 1
    .slice(0, 8) // Limit to the first 11 items
    .map((serv, index) => (
      serv.image
        .filter(i => i.vertical_banner !== undefined) // Filter images with a vertical banner
        .map((i, imgIndex) => ( // Use imgIndex for unique keys within filtered images
          <li 
            className="carousel-item" 
            style={{
              '--_index': `${index}`,
              '--_image-url': `url(https://backend.corporateeventplanner.in/storage/service_gallery/${i.vertical_banner})`
            }} 
            key={`${index}-${imgIndex}`} // Ensure unique keys
          >
            <a href={serv.service_url}>{serv.service}</a>
          </li>
        ))
    ))
}


                            {/* <li className="carousel-item" style={{ '--_index': 11, '--_image-url': `` }}>
                                <a href="#" target="_blank" rel="noopener noreferrer">
                                   <p> View all</p>
                                </a>
                            </li> */}
                    </ul>  
          
          {/* Add more carousel items as needed */}
       
      </div>
    </div>
    </div>
    </div>
  )
}

export default Indexnewbanner
