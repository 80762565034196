import React from 'react'
import ContactBanner from './ContactBanner';
import ContactForm from './ContactForm';
import './Contact.css';
import MetaTag from '../../components/MetaTag';
const Contact = () => {
  return (
    <>
    <MetaTag noindex={false} titles="Contact Us - CYJ Events | Best Corporate Event Planner Near You" metakeywords="cyj events, corporate event planner, best corporate event planner, top rated  corporate event planner, best event planner in india, event planner in india, best corporate event planner in india" metaDescriptions="Contact Us - CYJ Events | Best Corporate Event Planner Near You" hrefLinks={window.location.href}/>
    <ContactBanner />
    <ContactForm />
    </>
  )
}

export default Contact
