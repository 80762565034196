import React, { useEffect, useState } from 'react';
import PageBanner from '../../components/PageBanner';
import './LocationPageCss/Location.css';
import { Link } from 'react-router-dom';
import MetaTag from '../../components/MetaTag';
const Location = () => {
    const [locationData, setLocationData] = useState([]);
        
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchApiData = async () => {
            try {
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-location');
                const data = await result.json();
                setIsLoading(false);
                setLocationData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchApiData();
    }, []);




    let lokdta = null;

    if (isLoading) {
        lokdta = <p>Loading...</p>;
    } else {
       
        lokdta = locationData.map((item, index) => (
            <div className="alllokcards" key={index}>
                <div className="imgallLok">
                    <img src={`https://backend.corporateeventplanner.in/storage/location-gallery/${item.location_banner}`} alt="" />
                </div>
                <div className="lokallhead">
                    <h3>{item.location}</h3>
                    <p>{item.small_description}</p>
                    {/* Use Link component properly */}
                    <Link to={`../${item.location_url}`}>View</Link>
                </div>
            </div>
        ));
    }


    return (
        <>
        <MetaTag noindex={false} titles="Location title" metakeywords="Location keywords in gurgaon" metaDescriptions="Location Description 1" hrefLinks={window.location.href}/>
            <PageBanner image="image/image5.jpg" bannerheading="Location Wise" heading="Location Wise" pageLink="/location" />
            <section className="allLocationpageSection"
                // style={{
                //     background: `url("image/bg_decor_left.png"), url("image/bg_decor_right.png")`,
                //     backgroundPosition: 'bottom left, top right',
                //     backgroundRepeat: 'no-repeat',
                // }}
            >
                <div className="Heading">
                    <span className="topheading">All</span>
                    <div className="sbheadings">
                        <p>Lorem ipsum dolor</p>
                        <h2>Location</h2>
                        <p>Lorem ipsum dolor</p>
                    </div>
                </div>
                <div className="allLocationpageBox">
                    {lokdta}
                </div>
            </section>
        </>
    );
};

export default Location;
