
import React, {useEffect, useState, useRef} from 'react';
import Nearchandigarhresort from './Nearchandigarhresort'
import './Nearall.css';
import IndexTopClient from '../IndexPage/IndexTopClient';
import IndexTestimonial from '../IndexPage/IndexTestimonial';
import MetaTag from '../../components/MetaTag';


  const Nearchandigarh = () => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleContent = () => {
        setIsExpanded(!isExpanded);
    };

    const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
  }, []);


  return (
    <>
    <MetaTag noindex={false} titles="50+ Corporate Offsite Venues Near Chandigarh | Corporate Team Outing Places Near Chandigarh" metakeywords="corporate offsite venues near chandigarh, corporate offsite near chandigarh, corporate team outing near chandigarh, corporate outing near chandigarh, corporate team outing places near chandigarh, corporate event venues near chandigarh" metaDescriptions="Host Your Next Corporate Event with CYJ Events and Choose from over 50+ Corporate Offsite Venues Near Chandiagrh. Whether it's a corporate event, conference, seminar, outbound training, or team offsite, we offer the best options to cater your requirement. Call us now at 8130781111 or 8826291111 to book these remarkable venues near Chandigarh" hrefLinks={window.location.href}/>
    <div>
      <div className="neardelhi-topbanner">
          <div className="left-content">
           <img src='image/text-head2.png'/>
            <p>
            Corporate offsites Near Chandigarh serve as a refreshing retreat for professionals in need of a mental reset. A weekend spent with the team not only strengthens bonds but also energizes the workforce. These outings strike the perfect balance between productivity and relaxation, fostering a stress-free and invigorating experience.

            </p>
          </div>
          <div className="right-content">
            <div className="video-bubble">
              <video className="bubble-video" ref={videoRef} autoPlay muted loop>
                <source src="/videos/nearchandigarh.mp4" type="video/mp4" />
              </video>
              <h2>Near Chandigarh</h2>
            </div>
          </div>
      </div>
      <div className='neardelhiresorts-section'>
        <h1>FIND THE PERFECT DESTINATIONS FOR CORPORATE OFFSITES NEAR CHANDIGARH</h1>
         <p className={`content ${isExpanded ? 'expanded' : ''}`}>
         Corporate Offsites are popular events for companies that spend a lot of money to make their employees feel good. These types of events improve productivity and engage employees. Corporate offsites provide a unique opportunity to break the normal routine and encourage employees to do more than they have been doing.<br></br><br></br>

         There are a lot of places near Chandigarh if you are looking for corporate venues. You can choose from a range of destinations like Ropar, Solan, Kasauli, Shoghi, Shimla, Chail, etc. All these places are filled with green meadows offering a great Himalayan views while you enjoy your corporate event. It is also said that these locations are perfect for corporate groups who are also looking for corporate team outings near Chandigarh. The resorts here offer great corporate venues along with team building activities and make your event a full filled with work with excitement. <br></br><br></br>

         There are a ton of benefits if we talk about hosting corporate offsite events. First and foremost, the employees step away from the daily grind of office routines, reducing stress and burnout. This change in environment makes your bond better with your co-workers. Moreover, this event also lets people gather from different departments in one place and get to know each other. This helps in making communication improve, and enhances interpersonal relationships, leading to stronger teamwork.<br></br><br></br>

         Other than this, corporate offsites also offer platforms for skill development through workshops, training sessions, or any interactive team-building activities. This experience everyone should have at least once in a lifetime as this not only includes better bonding but also aligns the whole team with company goals. Overall, the corporate offsites not only work towards a healthy work-life but also make a healthier work culture and long-term business success. <br></br>
         If you are looking for someone who can make your corporate offsite near Chandigarh a beautiful and blissful experience then CYJ (Comfort Your Journey) is the best for everyone. With a deep understanding of corporate and hosting more than 100 events, we have a big team of people. We not only listen to your requirements but also make sure that you do not feel any problems while you enjoy the event. Our expertise in designing tailor-made experiences ensures that you have the best time of your event without any fail or hassle.<br></br><br></br>

         Find the Perfect Corporate Offsite Venues Near Chandigarh for a Memorable and Refreshing Getaway. 


            </p>
            {/* <button onClick={toggleContent} className="read-more-btn">
                {isExpanded ? 'Read Less ↑' : 'Read More ↓'}
            </button> */}
            <div onClick={toggleContent} className="read-more-btn">
              <span></span>
               <i className={`fa-solid fa-circle-arrow-${isExpanded ? 'up' : 'down'}`}></i>
              <span></span>
            </div>
           
        <Nearchandigarhresort/>

        <p className='bottom-neardelhi-content'>
                

              
            </p>
      </div>
      <div style={{backgroundColor:'#2e073f', paddingTop:'1rem',
        backgroundImage: 'linear-gradient(150deg, #2E073F, #76308d, #2E073F)'
      }} >
        <IndexTestimonial/>
      </div>
      <IndexTopClient/>
    </div>
    </>
  )
}

export default Nearchandigarh
