import React from 'react';
import { NavLink } from 'react-router-dom';
import './AboutPageCss/AboutPageInfo.css';
const AboutPageInfo = () => {
  return (
    <section className="aboutinfoSection" >
    <div className="inneraboutinfoSection">
        <div className="leftAboutInfo">
            <h2>At CYJ, we bring your vision to life, crafting seamless and unforgettable events, from team outing to corporate functions. </h2>
            <p id="subabt">Our dedicated team carefully plans and executes every detail to deliver smooth, unforgettable experiences. </p>
            <p>At CYJ, we oversee every detail of your event, from initial brainstorming to seamless execution, with a blend of expertise and creativity. We believe in collaborating closely with our clients to truly capture their vision and deliver beyond their expectations. With a strong focus on excellence, innovation, and personalized service, we’re here to create unforgettable events that leave a lasting mark. Let us bring your ideas to life and ensure your event is nothing short of extraordinary. To give you a sense of our process, we proudly share a glimpse into the standards and values that guide our work.</p>
                <NavLink to="/contact">Let's Talk!</NavLink>
        </div>
        <div className="rightAboutInfo">
            <div className="innerrightAboutInfo">
                <div className="firstinfoimgbox animatab">
                    <img src="image/image5.jpg" alt="" />
                </div>
                <div className="secoundinfoimgbox">
                    <div className="innersecundimg animatab">
                        <img src="image/image7.jpg" alt="" />
                    </div>
                    <div className="innersecundimg animatab">
                        <img src="image/image4.jpg" alt="" />
                    </div>
                </div>
                <div className="thirdinfoimgbox">
                    <NavLink to="/corporate-event-services" className="innerThirdtext animatab">
                        <i className="fa-solid fa-gears"></i>
                        <h3>Our Service</h3>
                        <p>Explore</p>
                    </NavLink>
                </div>
            </div>

        </div>
    </div>
</section>

  )
}

export default AboutPageInfo
