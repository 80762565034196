import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const targetLinks = document.querySelectorAll('.socialLink');

        targetLinks.forEach(link => {
            if (link.target !== "_blank") {
                return
            }
            window.scrollTo(0, 0);
        });
    }, [pathname]);

    return null;
};

export default ScrollTop;
