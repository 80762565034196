import React, { useEffect } from 'react'
import IndexTopClient from './IndexTopClient';
import IndexAbout from './IndexAbout';
import IndexService from './IndexService';
import IndexLocation from './IndexLocation';
import IndexWhyChooseUs from './IndexWhyChooseUs';
import IndexGallery from './IndexGallery';
import IndexOurUps from './IndexOurUps';
import IndexTestimonial from './IndexTestimonial';
import './indexpageCss/IndexDevice.css';
import MetaTag from '../../components/MetaTag';
import Indexnewbanner from './Indexnewbanner';
const Index = () => {

  return (
    <div style={{backgroundColor:"#2E073F"}}>
    <MetaTag noindex={false} titles="CYJ Events - Your Trusted Corporate Event Planner in India | Crafting Unforgettable Corporate Events That Foster Connection and Collaboration" metakeywords="cyj events, corporate event planner, best corporate event planner, top rated  corporate event planner, best event planner in india, event planner in india, best corporate event planner in india" metaDescriptions="We are your trusted Corporate Event Planners, proudly serving over 100+ clients and delivering unforgettable experiences for their teams. Our events are uniquely tailored, thoughtfully curated, and designed to engage every participant. Specializing in highly customized retreats and events, we focus on fostering connection and collaboration. Let us help you create an extraordinary experience for your team! To learn more, call us at 8130781111 or 8826291111" hrefLinks={window.location.href}/>
    {/* <IndexBanner /> */}
    <Indexnewbanner/>
    {/* <IndexTopClient /> */}
    <IndexAbout />
    <IndexService />
    <IndexLocation />
    <IndexWhyChooseUs />
    <IndexGallery />
    <div className='usp-index' >
      <IndexOurUps />
    </div>
    <IndexTestimonial />

    </div>
  )
}

export default Index
