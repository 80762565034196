import React from 'react';
import './termsandconditions.css';

function Termsandconditions() {
  return (
    <>
      {/* Banner Section */}
      <div className="terms-banner">
        <div className="banner-content">
          <h1>Terms and Conditions</h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="terms-container">
        <p>
          Welcome to our website. These Terms and Conditions govern your use of our services. By accessing or using our website, you agree to comply with these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our services.
        </p>

        <h2>1. Use of Services</h2>
        <ul>
          <li>You must provide accurate and complete information when engaging with our services to ensure smooth communication and coordination.</li>
          <li>Our services are intended for lawful purposes only. You may not misuse the website or engage in activities that could harm its functionality or compromise its security.</li>
        </ul>

        <h2>2. Booking and Transactions</h2>
        <ul>
          <li><strong>Booking Confirmation:</strong> All bookings are subject to availability and confirmation by our team and service providers.</li>
          <li><strong>Quotations:</strong> Service details, including costs, are provided to clients through direct communication. Any updates or modifications to quotes will be conveyed promptly.</li>
          <li><strong>Payments:</strong> Full payment must be made as per the agreed terms during the service arrangement process unless specified otherwise.</li>
        </ul>

        <h2>3. Cancellation Policy</h2>
        <ul>
          <li>
            <strong>Cancellations by the Client:</strong>
            <ul>
              <li>Cancellation requests must be communicated directly to our team via email, phone, or in-person consultation.</li>
              <li>Cancellation charges may apply based on the timeline and nature of the booking. Specific details will be provided at the time of booking.</li>
            </ul>
          </li>
          <li>
            <strong>Cancellations by the Company:</strong>
            <ul>
              <li>We reserve the right to cancel a booking due to unforeseen circumstances. In such cases, clients will be notified promptly, and efforts will be made to provide suitable alternatives or refunds.</li>
            </ul>
          </li>
        </ul>

        <h2>4. Refund Policy</h2>
        <p>
          <strong>Eligibility for Refunds:</strong> Refunds will be processed in accordance with the cancellation timeline agreed upon during booking confirmation. Some bookings may be non-refundable due to third-party service provider policies mentioned at the time of booking confirmation.
        </p>
        <p>
          <strong>Refund Processing:</strong> Refunds will be initiated within 15 business days of approval and credited through the original payment method or another agreed mode.
        </p>
        <p>
          <strong>Exclusions:</strong> No refunds will be provided for no-shows, partial utilization of services, or cancellations made outside the specified timelines.
        </p>

        <h2>5. Intellectual Property</h2>
        <ul>
          <li>All content on this website, including text, images, graphics, and logos, is the intellectual property of the company or its licensors and is protected under applicable laws.</li>
          <li>Unauthorized reproduction, distribution, or use of website content is strictly prohibited without prior written consent.</li>
        </ul>

        <h2>6. Limitation of Liability</h2>
        <ul>
          <li>We are not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services, including but not limited to financial loss, business interruption, or personal injury.</li>
          <li>The company’s total liability for any claim will not exceed the total amount paid by the client for the related service.</li>
        </ul>

        <h2>7. Indemnification</h2>
        <p>
          You agree to indemnify and hold the company harmless from any claims, damages, or losses arising from your use of our services or any breach of these Terms and Conditions.
        </p>

        <h2>8. Changes to Terms and Conditions</h2>
        <p>
          We reserve the right to modify these Terms and Conditions at any time. Updated terms will be posted on this website and will take effect immediately upon posting. Continued use of our services signifies your acceptance of the updated terms.
        </p>

        <p>
          For further information or inquiries regarding these Terms and Conditions, please contact us directly through the details provided on the website.
        </p>
      </div>
    </>
  );
}

export default Termsandconditions;
