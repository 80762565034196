import React, { useEffect, useState } from 'react';
import PageBanner from '../../components/PageBanner';
import ServiceAbout from './ServiceAbout';
import ServiceSection from './ServiceSection';
import { useParams } from 'react-router-dom';
import MetaTag from '../../components/MetaTag';
const Service = () => {
  const {urls} = useParams();

  const [data, setData] = useState([]);

  useEffect(()=>{
    fetch(`https://backend.corporateeventplanner.in/api/cyj-service/${urls}`)
    .then((response)=>response.json())
    .then((result)=>setData(result))
    .catch((error)=>console.log(error))
  },[urls]);

  return (
   <>{
    data.map((itm, index)=>(
      
      <>
      <MetaTag key={index} noindex={false} titles={itm.metaTitle} metakeywords={itm.metaKeywords} metaDescriptions={itm.metaDescription} hrefLinks={window.location.href}/>

      <PageBanner image={`https://backend.corporateeventplanner.in/storage/service_gallery/${itm.service_banner}`} bannerheading={itm.service} heading="Service" pageLink="../corporate-event-services" client={`${itm.service}`} clientLink={`../${itm.service_url}`}/>
      </>
      
    ))
   }
   <ServiceAbout aboutdata = {data}/>
    <ServiceSection longtext={data} />
   </>
  )
}

export default Service;
