import React, { useEffect, useState } from 'react';
import './LocationPageCss/LocationDetail.css';
import PageBanner from '../../components/PageBanner';
import LocationDetailAbout from './LocationDetailAbout';
import LocationDetailSection from './LocationDetailSection';
import MetaTag from '../../components/MetaTag';
import Locationvideo from './Locationvideo';
import PageFrom from '../../components/PageFrom';
import IndexTopClient from '../IndexPage/IndexTopClient';
import IndexTestimonial from '../IndexPage/IndexTestimonial';

const LocationDetail = (props) => {
  const urls = props.urls;
  
  // const {urls} = useParams();
  
  const [dta, setData] = useState([]);
  
  useEffect(()=>{
        fetch(`https://backend.corporateeventplanner.in/api/cyj-location/${urls}`)
        .then((response)=>response.json())
        .then((result)=>setData(result))
        .catch((error)=>console.log(error))
  },[urls]);


  const [showForm, setShowForm] = useState(false);

  // Function to open the form
  const openForm = () => {
    setShowForm(true);
  };

  // Function to close the form
  const closeForm = () => {
    setShowForm(false);
  };
  
  const [odta, setOdata]=useState([]);
  const firstId = dta.length > 0 ? dta[0].id : null;
  useEffect(()=>{
   fetch(`https://backend.corporateeventplanner.in/api/cyj-location-other-data/${firstId}`)
     .then((response)=>response.json())
     .then((result)=>setOdata(result))
     .catch((error)=>console.log(error))
   },[firstId]);

   const staticIcons = ["image/icon/icon1.png", "image/icon/icon2.png", "image/icon/icon3.png", "image/icon/icon4.png", "image/icon/icon5.png", "image/icon/icon6.png"];

  //  const icons = odta.map(i => i.LocationIcons);
  // dynamic icons list 

  
  const icons =["image/icon/icon1.png", "image/icon/icon2.png", "image/icon/icon3.png", "image/icon/icon4.png", "image/icon/icon5.png", "image/icon/icon6.png"];  // replace of dynamic icons



   const iconArrays = Array.from({ length: 6 }, (_, index) =>
     icons.map(x => x[index]?.location_icons ? 
       `https://backend.corporateeventplanner.in/storage/location-gallery/${x[index]?.location_icons}` : 
       staticIcons[index]
     )[0] 
   );
   
   const [icon1, icon2, icon3, icon4, icon5, icon6] = iconArrays;

  return (
    <div>
      <div className="inner-body">
      {
        dta.map((itm, indx)=>(
            <MetaTag noindex={false} titles={itm.metaTitle} metakeywords={itm.metaKeywords} metaDescriptions={itm.metaDescription} hrefLinks={window.location.href}/>
        ))
      }
      

      { dta.map((itm, index) => (
      <div className="locationbanner">
        <div className="location-banner-image">
         <img src={`https://backend.corporateeventplanner.in/storage/location-gallery/${itm.location_banner}`} alt={itm.location}/>
        </div>
        <div className="text-banner" >
          <h1
          //  style={{color:`${itm.lightColor}`}} 
           ><span>#1 </span>Rated <span
            // style={{color:`${itm.darkColor}`}} 
            >Corporate Event Planner</span>  in {itm.location}</h1>
          {/* <p>{itm.heading}</p> */}
          <a onClick={openForm}
          //  style={{backgroundColor:`${itm.darkColor}`}} 
           >Get Quote</a>
        </div>
        <img 
  src={icon1} 
  className='icon1' 
  alt="Icon 1"
/>
<img 
  src={icon5} 
  className='icon5' 
  alt="Icon 5"
/>
      </div>
       ))
      }
     <LocationDetailAbout data={dta} icon2={icon2} icon3={icon3} />
     
      <LocationDetailSection data={dta} icon4={icon4}  />
      <Locationvideo data={dta} />

     




<img 
  src={icon6} 
  className='icon6' 
  alt="Icon 6"
/>

      </div>
      {showForm && (
         <div className="modal">
         <div className="modal-content">
           <span className="closeBtn" onClick={closeForm}>
             &times;
           </span>
      <PageFrom/>
      </div>
      </div>)}
      <div style={{backgroundColor:'#2e073f', paddingTop:'1rem',
        backgroundImage: 'linear-gradient(150deg, #2E073F, #76308d, #2E073F)'
      }} >
        <IndexTestimonial/>
      </div>
      <IndexTopClient/>
    </div>
  )
}

export default LocationDetail; 
