import React, { useEffect } from 'react'
import PageBanner from '../../components/PageBanner';
import AboutPageInfo from './AboutPageInfo';
import IndexOurUps from '../IndexPage/IndexOurUps';
import AboutPageVission from './AboutPageVission';
import AboutPageClient from './AboutPageClient';
import './AboutPageCss/AboutDevice.css';
import MetaTag from '../../components/MetaTag';
const About = () => {

 

  return (
    <div style={{backgroundColor:'#2E073F'}} >
    <MetaTag noindex={false} titles="About Us - CYJ Events | Best Corporate Event Planner Near You
    " metakeywords="cyj events, corporate event planner, best corporate event planner, top rated  corporate event planner, best event planner in india, event planner in india, best corporate event planner in india" metaDescriptions="About Us - CYJ Events | Best Corporate Event Planner Near You" hrefLinks={window.location.href}/>
    <PageBanner image="image/image5.jpg" bannerheading="About Us" heading="About Us" pageLink="/about" />
    <AboutPageInfo />
    <IndexOurUps />
    <AboutPageVission />
    <AboutPageClient />
    </div>
  )
}

export default About
