import React from 'react';
import './privacy.css';
import MetaTag from '../../components/MetaTag';

function Privacypolicy() {
  return (
    <>
     <MetaTag noindex={false} titles="Privacy Policy - CYJ Events | Best Corporate Event Planner Near You" metakeywords="Privacy Policy - CYJ Events | Best Corporate Event Planner Near You" metaDescriptions="Privacy Policy - CYJ Events | Best Corporate Event Planner Near You" hrefLinks={window.location.href}/>
     <div className="privacy-policy-banner">
        <div className="banner-content">
          <h1>Privacy Policy</h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="privacy-policy-container">
      <p>This Privacy Policy is designed to inform you about how we collect, use, protect, and disclose your personal information when you use our event planning services. By using our website and services, you consent to the collection and use of your information as described below. Please read this policy carefully.</p>

      <h2>1. Information We Collect</h2>
      <p>We collect information from you when you register on our site, make a payment, place an order, or enter information on our website. The types of information we may collect include:</p>
      <ul>
        <li><strong>Personal Information:</strong> Information such as your name, email address, phone number, and physical address, which you provide during registration, bookings, or inquiries.</li>
        <li><strong>Transaction Information:</strong> Details of any transactions made on our website, including payment information and booking details.</li>
        <li><strong>Usage Data:</strong> Information about how you use our website, such as the pages you visit, your IP address, device information, and interaction with our content.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We may use the information we collect from you in the following ways:</p>
      <ul>
        <li>To personalize your experience and provide the content, products, and services you are most interested in.</li>
        <li>To improve our website and services based on feedback and usage data.</li>
        <li>To respond to your customer service requests more efficiently.</li>
        <li>To process your bookings, payments, and transactions quickly and securely.</li>
        <li>To administer contests, promotions, or surveys that you participate in.</li>
        <li>To follow up with you after bookings, inquiries, or other interactions via email, live chat, or phone.</li>
        <li>To send promotional emails about new services, discounts, or updates that may interest you (if you have opted-in).</li>
      </ul>

      <h2>3. How We Protect Your Information</h2>
      <p>We prioritize the security of your personal information. To maintain the safety of your data:</p>
      <ul>
        <li>Our website is regularly scanned for security vulnerabilities and malware.</li>
        <li>We use Secure Socket Layer (SSL) encryption for all sensitive information, including payment details.</li>
        <li>Your personal information is stored behind Secure Networks and can only be accessed by authorized personnel.</li>
        <li>Transactions are processed through a secure third-party gateway provider and are not stored on our servers.</li>
      </ul>

      <h2>4. Use of Cookies</h2>
      <p>We use cookies to enhance your experience on our website. Cookies are small files stored on your device that help us remember your preferences, track your booking information, and improve site performance. You may choose to disable cookies through your browser settings if you prefer not to have them.</p>
      <ul>
        <li>Remember your booking details and preferences.</li>
        <li>Track advertisements and interactions on our site.</li>
        <li>Improve the overall site experience.</li>
      </ul>

      <h2>5. Third-Party Links</h2>
      <p>Occasionally, we may provide links to third-party websites for products or services related to event planning. These third-party sites have their own privacy policies, and we are not responsible for their practices. We encourage you to review their privacy policies before providing any personal information.</p>

      <h2>6. Data Security</h2>
      <p>We have implemented robust security measures to protect your information, including firewalls and real-time monitoring systems. Any suspicious activity or fraudulent transactions are investigated promptly to maintain the integrity of our services.</p>

      <h2>7. Sharing of Information</h2>
      <p>We value your privacy and will not share your personal information with third parties without your prior consent, except where necessary to fulfill our contractual obligations (such as processing payments or delivering services). We do not sell or rent your information to third parties for marketing purposes.</p>

      <h2>8. Your Rights</h2>
      <p>You have the right to access, correct, or delete the personal information we hold about you. You can also opt out of marketing communications at any time by following the unsubscribe instructions in our emails or by contacting us directly.</p>

      <h2>9. Changes to the Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, with the updated date indicated at the top of the policy. We encourage you to review this policy periodically to stay informed about how we protect your personal information.</p>

      <h2>10. Contact Us</h2>
      <p>If you have any questions or concerns about our privacy practices or wish to exercise your rights, please contact us at:</p>
      <ul>
        <li><strong>Website:</strong> <a href="http://www.corporateeventplanner.in" target="_blank" rel="noopener noreferrer">www.corporateeventplanner.in</a></li>
        <li><strong>Email:</strong> <a href="mailto:info@cyjevents.com">info@cyjevents.com</a></li>
        <li><strong>Phone:</strong> <a href="tel:+918130781111">8130781111</a>, <a href="tel:+918826291111">8826291111</a></li>
      </ul>

      <p>We value your trust and are committed to protecting your personal information as you use our event planning services.</p>
      </div>

    </>
  );
}

export default Privacypolicy;
