import React, { useEffect, useState } from 'react';
import './Footers.css';
import { Link } from 'react-router-dom';
import FooterForm from './FooterForm';

const Footers = () => {
    

    const [locationData, setLocationData] = useState([]);
    useEffect(() => {
        const fetchApiData = async () => {
            try {
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-location');
                const data = await result.json();
                setLocationData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchApiData();
    }, []);


    const [serdta, setServdata]=useState([]);
    useEffect(()=>{
        const servicedata = async()=>{
            try{
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-service');
                const data = await result.json();
                setServdata(data);
            }catch(error){
                console.log(error);
            }
        }
        servicedata();
    }, []);


   

  return (
    <>
    
    <footer>
    <div className="innerFooter">
        <div className="firstFooter">
            <div className="footerboxOne">
                <div className="footerlogo">
                    <img src="../image/whitelogo.png" alt="" />
                </div>
                <p>We are a premier Corporate Event Planner based in Delhi NCR specializing in curated corporate events, corporate outbounds & team-building activities, and travel experiences across India & overseas. With years of expertise and a passion for crafting memorable experiences, we offer a diverse range of services, including meticulously planned corporate offsites, luxury retreats, and customized group events.</p>
                <ul className="footerSocial">
                    <li><Link to="https://twitter.com/Official_CYJ/" target="_blank" className='socialLink'><i className="fa-brands fa-twitter"></i></Link></li>
                    <li><Link to="https://www.instagram.com/official_cyj/" target="_blank" className='socialLink'><i className="fa-brands fa-instagram"></i></Link></li>
                    <li><Link to="https://www.facebook.com/OfficialCYJ/" target="_blank" className='socialLink'><i className="fa-brands fa-facebook-f"></i></Link></li>
                    <li><Link to="https://www.linkedin.com/company/cyj/" target="_blank" className='socialLink'><i className="fa-brands fa-linkedin-in"></i></Link></li>
                </ul>
            </div>
            <div className="footerboxTwo">
                <ul className="footerMenu">
                    <h3>IMPORTANT LINK</h3>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/corporate-event-services">Our Services</Link></li>
                    <li><Link to="/india">India</Link></li>
                    <li><Link to="/international">International</Link></li>
                    <li><Link to="/our-portfolio">Our Portfolio</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>

                {/* <ul className="footerMenu">
                    <h3>OTHER LINKS</h3>
                    <li><Link to="">Blog</Link></li>
                    <li><Link to="">Payment</Link></li>
                    <li><Link to="">Policy</Link></li>
                    <li><Link to="">Terms & Conditions</Link></li>
                    <li><Link to="">Payments Policy</Link></li>
                </ul> */}

                <ul className="footerMenu">
                    <h3>CONTACT US</h3>
                    <li><a href="tel:8130781111"><i className="fa-solid fa-phone"></i> +91-8130781111</a></li>
                    <li><a href="tel:8826291111"><i className="fa-solid fa-phone"></i> +91-8826291111</a></li>
                    <li><a href="mailto:info@cyjevents.com"><i className="fa-solid fa-envelope"></i> info@cyjevents.com</a></li>
                    {/* <li>
                        <address>
                            <b>Address :</b>  #308, Third Floor, Suncity Arcade, Sector 54, Gurugram, Haryana 122003
                         </address>
                    </li> */}
                  
                </ul>
            </div>

        </div>

    </div>
    {/* <FooterForm /> */}
    {/* <div className="topSearchKeywords">
        <h3>Top Searching</h3>
        <ul>
        {
            locationData
            .filter(itm=>itm.country == 'International')
            .map((itm, index)=>(
                <li key={index}><Link to={itm.location_url}>{itm.location} </Link></li>
            ))
        }
        
        </ul>
        <ul>
        {
            locationData
            .filter(itm=>itm.country == 'India')
            .map((itm, index)=>(
                <li key={index}><Link to={itm.location_url}>{itm.location} </Link></li>
            ))
        }
        
        </ul>
        <ul>
                {
                    serdta
                    .filter((i)=>i.status === 1)
                    .map((serv, index)=>(
                        <li key={index}><Link to={serv.service_url} className="subLink">{serv.service}</Link></li>
                    ))
                }
        </ul>
    </div> */}

<div className="topSearchKeywords">
  <h3>Top Searching</h3>
  <ul>
    {
      [
        // Combine all dynamic and static links
        ...locationData.filter(itm => itm.country === 'International').map(itm => ({
          name: itm.location,
          url: itm.location_url
        })), 
        ...locationData.filter(itm => itm.country === 'India').map(itm => ({
          name: itm.location,
          url: itm.location_url
        })), 
        ...serdta.filter(i => i.status === 1).map(serv => ({
          name: serv.service,
          url: serv.service_url,
          className: 'subLink boldText'
        })), 
        // Add static links
        { name: 'Corporate Offsite Near Delhi', url: '/corporate-offsite-venues-near-delhi', className: 'staticLink boldText' },
        { name: 'Corporate Offsite Near Mumbai', url: '/corporate-offsite-venues-near-mumbai-and-pune', className: 'staticLink boldText' },
        { name: 'Corporate Offsite Near Pune', url: '/corporate-offsite-venues-near-mumbai-and-pune', className: 'staticLink boldText' },
        { name: 'Corporate Offsite Near Chandigarh', url: '/corporate-offsite-venues-near-chandigarh', className: 'staticLink boldText' }
      ]
      .sort(() => Math.random() - 0.5) // Shuffle the combined array
      .map((item, index) => (
        <li key={index}>
          <Link to={item.url} className={item.className || ''}>
            {item.name}
          </Link>
        </li>
      ))
    }
  </ul>
</div>



    <div className="finalFooterBox">
        <p>Copyrights © {new Date().getFullYear()} | Comfort Your Journey Pvt Ltd. | All Rights Reserved</p>
        <div className='privacy-link'>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <Link to="/disclaimer">Disclaimer</Link>
        </div>
    </div>
</footer>
    </>
  )
}

export default Footers;
