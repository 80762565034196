import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Autoplay, Navigation } from 'swiper/modules';

const LocationDetailLeft = (props) => {
    const { icon3 } = props;
    const datas = props.datass;
    const id = datas.length > 0 ? datas[0].id : null;

    const [rdata, setRdata] = useState([]);
    const [selectedResort, setSelectedResort] = useState(null);
    const [currentGallery, setCurrentGallery] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
        pageUrl: window.location.href,
    });
    const [errors, setError] = useState('');

    const handlePopupOpen = async (resort) => {
        setSelectedResort(resort);
        setError('');
        setFormData({
            ...formData,
            name: '',
            phone: '',
            email: '',
            message: '',
        });

        try {
            const response = await fetch(
                `https://backend.corporateeventplanner.in/api/cyj-resorts-gallery/${resort.id}`
            );
            const galleryData = await response.json();
            setCurrentGallery(galleryData);
        } catch (error) {
            console.log('Error fetching gallery data:', error);
        }
    };

    const handlePopupClose = () => {
        setSelectedResort(null);
        setCurrentGallery([]);
        setError('');
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const website_name = 'Corporate Planner';
    const indiaDateTime = new Date().toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour12: false,            
      weekday: 'long',         
      year: 'numeric',          
      month: 'long',        
      day: 'numeric',       
      hour: '2-digit',         
      minute: '2-digit',       
      second: '2-digit'   
    });

    const formattedDateTime = indiaDateTime.replace(/,/g, ''); 

    let contact = '';
    if (formData['name'] !== '') contact += `Name:-${formData['name']}, `;
    if (formData['phone'] !== '') contact += `Mobile:-${formData['phone']}, `;
    if (formData['email'] !== '') contact += `Email:-${formData['email']}, `;
    contact += `Resorts:-${selectedResort ? selectedResort.resorts_name : ''}, `;
    if (formData['pageUrl'] !== '') contact += `Url:-${formData['pageUrl']}, `;
    contact += `Date:-${formattedDateTime}`;
    const messages = formData['message'];

    const formdta = {
      website_name: website_name,
      contact: contact,
      message: messages
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://leads.hotelstourandtravel.com/api/cyj-leads-insert', formdta);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            }).then(() => {
                handlePopupClose();
            });
        } catch (error) {
            setError(error.response.data);
        }
    };

    useEffect(() => {
        if (id) {
            fetch(`https://backend.corporateeventplanner.in/api/cyj-resorts-data/${id}`)
                .then((response) => response.json())
                .then((result) => setRdata(result))
                .catch((error) => console.log(error));
        }
    }, [id]);

    const shouldHideSection = !datas || datas.length === 0 || rdata.length === 0;
    const darkColor = datas.length > 0 ? datas[0].darkColor : null;

    return (
        <div className="locationleftSection" style={{ display: shouldHideSection ? 'none' : 'block' }}>
            <img src={icon3} className="icon3" alt="Icon 3" />

            <div className="locationResortsBox">
                <div className="lokreortheadings">
                    <h2
                    //  style={{ color: `${darkColor}` }}
                     >
                        Resorts in {datas.map((L) => L.location)} for Corporate Events
                    </h2>
                </div>
                <div className="lokresortBoxs">
                    {rdata.map((resort, index) => (
                        <div className="locationResortCards" key={index}>
                            <div className="lokresrtImg">
                                <img
                                    src={`https://backend.corporateeventplanner.in/storage/resorts-gallery/${resort.resorts_banner}`}
                                    alt={resort.resorts_name}
                                />
                            </div>
                            <div className="lokreortText">
                                <h3>{resort.resorts_name}</h3>
                                <p
                                    className="shortdecresort"
                                    dangerouslySetInnerHTML={{ __html: resort.long_description }}
                                ></p>
                                <div className="links-resort">
                                    <button onClick={() => handlePopupOpen(resort)}>
                                    Send Enquiry
                                    </button>
                                    <a href="tel:8130781111">
                                         <i className="fa-solid fa-phone"> </i> Call
                                    Us</a>
                                </div>
                            </div>
                        </div>
                    ))}
                    {selectedResort && (
                        <div
                            className="popupOverlay locationpagepopup"
                            onClick={handlePopupClose}
                        >
                            <div
                                className="popupContent"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <button className="nearbyform-close" onClick={handlePopupClose}>
                                    ✖
                                </button>
                                <div className="nearbyform-left">
                                    <Swiper
                                        centeredSlides={true}
                                        rewind={true}
                                        navigation={true}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        speed={1000}
                                        modules={[Autoplay, Navigation]}
                                        className="mySwiper nearresortGalleryslider"
                                    >
                                        {currentGallery.map((img) => (
                                            <SwiperSlide
                                                className="nearresortsGallery"
                                                key={img.id}
                                            >
                                                <img
                                                    src={`https://backend.corporateeventplanner.in/storage/resorts-gallery/gallery/${img.resorts_gallery}`}
                                                    alt={img.resorts_name}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <h4>{selectedResort.resorts_name}</h4>
                                </div>

                                <div className="nearby-formright">
                                    <h3>Contact</h3>

                                    <form onSubmit={handleSubmit} className="popupForm">
                                        <label>Name:</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Enter your name"
                                        />
                                        <p style={{ color: 'red' }}>{errors.name}</p>

                                        <label>Phone:</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            placeholder="Enter your phone number"
                                        />
                                        <p style={{ color: 'red' }}>{errors.mobile}</p>

                                        <label>Email:</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Enter your email"
                                        />
                                        <p style={{ color: 'red' }}>{errors.email}</p>

                                        <label>Message:</label>
                                        <textarea
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            placeholder="Your message here..."
                                        />
                                        <p style={{ color: 'red' }}>{errors.message}</p>

                                        <button className="nearby-submit" type="submit">
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LocationDetailLeft;
