import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PageBanner from '../../components/PageBanner';
import './Resorts.css';
import ResortLeftSection from './ResortLeftSection';
import ResortRightSection from './ResortRightSection';
import MetaTag from '../../components/MetaTag';

const ResortPage = () => {
  const { location_url, urls } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [apiLocationUrl, setApiLocationUrl] = useState("");

  useEffect(() => {
    // Fetch resort data
    fetch(`https://backend.corporateeventplanner.in/api/cyj-resorts/${urls}`)
      .then((response) => response.json())
      .then((result) => {
        setData(result);
        if (result.length > 0) {
          const fetchedLocationUrl = result[0].location_url;
          setApiLocationUrl(fetchedLocationUrl);

          // Redirect to correct URL if location_url in the URL is incorrect
          if (location_url !== fetchedLocationUrl) {
            navigate(`/${fetchedLocationUrl}/${urls}`, { replace: true });
          }
        }
      })
      .catch((error) => console.log(error));
  }, [location_url, urls, navigate]);

if(data.length === 0){
  navigate('/404');
}


  return (
    <div>
      {data.map((itm, indx) => (
        <MetaTag
          key={indx}
          noindex={true}
          titles={itm.metaTitle}
          metakeywords={itm.metaKeywords}
          metaDescriptions={itm.metaDescription}
          hrefLinks={window.location.href}
        />
      ))}
      <PageBanner
        image={`https://backend.corporateeventplanner.in/storage/resorts-gallery/${
          data.length > 0 ? data[0].resorts_banner : null
        }`}
        heading={data.length > 0 ? data[0].resorts_name : null}
      />
      <section className="resortsaboutSection">
        <div className="innerresortsaboutSection">
          <ResortLeftSection data={data} />
          <ResortRightSection />
        </div>
      </section>
    </div>
  );
};

export default ResortPage;
