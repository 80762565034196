import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Error = () => {

  useEffect(()=>{
    const links = document.querySelectorAll('a');
    links.forEach(link=>{
      link.addEventListener('click', scrollTop);
    });
  
    return ()=>{
      links.forEach(link=>{
        link.removeEventListener('click', scrollTop);
      });
    }
  
  },[]);
  const scrollTop =()=>{
    window.scrollTo({
      top:0,
      behavior: 'smooth'
    });
  }
  return (
    <div style={{backgroundColor:'rgb(142 94 150)',
      overflow:'hidden'
    }} > 
      <img src="../image/giphy.gif" alt="404 Error" style=
      {{
        display:'block', 
        width: '20%',
        margin: '5% auto',
        marginBottom: '0',
       }} />

        <Link to="/" style={{
            display: 'block',
            margin: 'auto',
            textAlign: 'center',
            marginBottom: '2rem',
        }}>Go Main Page</Link>

    </div>
  )
}

export default Error;
