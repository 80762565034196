import React from 'react';
import './disclaimer.css';

function Disclaimer() {
  return (
    <>
      {/* Banner Section */}
      <div className="disclaimer-banner">
        <div className="banner-content">
          <h1>Disclaimer</h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="disclaimer-container">
        <p>
          Welcome to our event planning services! Before you explore our services, please take a moment to read this disclaimer carefully. By using our website and engaging with our services, you acknowledge that you have read, understood, and accepted the terms outlined below.
        </p>

        <h2>1. Information Accuracy and Updates</h2>
        <p>
          We strive to provide accurate and up-to-date information regarding our corporate event planning services. However, the nature of event planning involves dynamic factors that may lead to changes in service details, availability, or logistics. While we make every effort to ensure the accuracy and reliability of the information provided, we cannot guarantee that all details will remain unchanged or free from errors.
        </p>

        <h2>2. Service Availability and Changes</h2>
        <p>
          Our services are subject to availability and may be influenced by factors such as venue booking policies, third-party vendor commitments, or unforeseen circumstances. We act as intermediaries between clients and service providers. Consequently, we cannot be held liable for any changes, cancellations, or disruptions caused by external factors.
        </p>

        <h2>3. Customized Proposals and Quotations</h2>
        <p>
          All service quotations and proposals are provided through direct communication. These quotations are based on the client’s specific requirements and are subject to mutual agreement. We do not display or provide pricing information on our website. Any updates or modifications to quotations will be communicated promptly to the client.
        </p>

        <h2>4. Review of Terms and Conditions</h2>
        <p>
          Before finalizing arrangements, clients are advised to review the terms and conditions associated with their event plans. These terms are communicated during the service consultation process. By proceeding with our services, you agree to comply with these terms and conditions.
        </p>

        <h2>5. Data Security and Privacy</h2>
        <p>
          We are committed to protecting your personal and organizational information. While we employ robust security measures to safeguard your data, we cannot guarantee absolute protection against unauthorized access or breaches. Users are encouraged to exercise caution when sharing sensitive details.
        </p>

        <h2>6. Limitation of Liability</h2>
        <p>
          We are not responsible for any direct, indirect, incidental, or consequential damages arising from the use of our platform or services. This includes, but is not limited to, financial losses, event disruptions, or unmet expectations due to circumstances beyond our control.
        </p>

        <h2>7. Event Execution</h2>
        <p>
          As your trusted event planning partner, we collaborate with experienced vendors and service providers to deliver exceptional corporate events. However, we do not control third-party operations and cannot guarantee flawless execution in all instances. Clients are encouraged to maintain direct communication with us for any concerns or updates.
        </p>

        <h2>8. Dispute Resolution and Jurisdiction</h2>
        <p>
          In the event of a dispute arising from the use of our services or this platform, efforts will be made to resolve the issue amicably through mutual discussion. If a resolution cannot be reached, the matter will be subject to the exclusive jurisdiction of the courts in Delhi, India, and governed by the laws of India.
        </p>

        <h2>9. Use of Platform</h2>
        <p>
          By using our website and engaging with our services, you acknowledge that event-related decisions are made at your discretion. Our team is available to clarify any details or provide personalized assistance to ensure your event aligns with your expectations.
        </p>

        <h2>10. Customer Support</h2>
        <p>
          If you have questions, concerns, or require support during any stage of your event planning process, please contact our team directly. We are dedicated to making your corporate event a success.
        </p>

        <p>
          Thank you for choosing us as your corporate event planning partner. We look forward to helping you create seamless and memorable experiences for your organization!
        </p>
      </div>
    </>
  );
}

export default Disclaimer;
