import React, { useEffect, useState } from 'react'
import PageBanner from '../../components/PageBanner';
import './GalleryPageCss/GalleryClientDetail.css';
import GalleryClientDetailImage from './GalleryClientDetailImage';
import { useParams } from 'react-router-dom';
import MetaTag from '../../components/MetaTag';

const GalleryClientDetail = () => {
  const {gurl} = useParams();

  const [data, setData]=useState([]);
  useEffect(()=>{
    fetch(`https://backend.corporateeventplanner.in/api/cyj-client-gallery/${gurl}`)
    .then((response)=>response.json())
    .then((result)=>setData(result))
    .catch((error)=>console.log(error))
  },[gurl]);

  
  return (
    <div>
    <MetaTag 
      noindex={false} 
      titles={`${data.length > 0 ? `${data[0].name} - ` : ''}Corporate Offsite Image Gallery`} 
      metakeywords={`${data.length > 0 ? `${data[0].name}, ` : ''}Corporate Offsite Image Gallery`} 
      metaDescriptions={`${data.length > 0 ? `${data[0].name} - ` : ''}Corporate Offsite Image Gallery`} 
      hrefLinks={window.location.href} 
    />

       <PageBanner image="../image/image5.jpg" bannerheading={data.length>0?data[0].name:null} heading="Gallery" pageLink="/gallery" client={data.length>0?data[0].name:null} clientLink={data.length>0?data[0].curl:null} />
       <GalleryClientDetailImage gdatas={data}/>
    </div>
  )
}

export default GalleryClientDetail
