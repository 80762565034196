import React, { useEffect, useState } from 'react';
import './indexpageCss/IndexTopClient.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';

const IndexTopClient = () => {

    const [data, setData]=useState([0]);
  useEffect(()=>{
    fetch(`https://backend.corporateeventplanner.in/api/cyj-client-gallery`)
    .then((response)=>response.json())
    .then((result)=>setData(result))
    .catch((error)=>console.log(error))
  },[]);


    let siledview =4;
    let space = 40;
    if (window.innerWidth > 1150) {
        siledview = 5;
        space = 40;
    } else if (window.innerWidth > 750) {
        siledview = 5;
        space = 20;
    } else if (window.innerWidth > 500) {
        siledview = 3;
        space = 30;
    } else if (window.innerWidth > 300) {
        siledview = 3;
        space = 5;
    }
    else if (window.innerWidth < 300) {
        siledview = 2;
    }
    
   const slideers = data.map((logo, index)=>{
        return (
            <SwiperSlide className="clientCard" key={index}>
                <img src={`https://backend.corporateeventplanner.in/storage/client-gallery/${logo.logo}`} alt={logo.name} />
            </SwiperSlide> 
        )
   })      
    
  return (
    <section className="clientSection">
    <div className="innerClientSection">
        <Swiper
         slidesPerView={siledview}
         spaceBetween={space}
           loop={true}
           autoplay={{
               delay: 2500,
               disableOnInteraction: false,
           }}
           speed={1000}
           modules={[Autoplay]}
        
        className="mySwiper clientSlide">
                 {slideers}
        </Swiper>
    </div>
</section>

  )
}

export default IndexTopClient
