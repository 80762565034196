import React, { useEffect, useState } from 'react';
import PageBanner from '../../components/PageBanner';
import './LocationPageCss/Location.css';
import { Link } from 'react-router-dom';
import MetaTag from '../../components/MetaTag';
const International = () => {

    const [locationData, setLocationData] = useState([]);
        
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchApiData = async () => {
            try {
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-location');
                const data = await result.json();
                setIsLoading(false);
                setLocationData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchApiData();
    }, []);

    

    const [ldta, setLdata] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const results = await Promise.all(
            locationData.map(async (item) => {
                try {
                  const response = await fetch(
                    `https://backend.corporateeventplanner.in/api/cyj-location-other-data/${item.id}`
                  );
                  const result = await response.json();
                  return {
                    location_id: item.id,
                    thumbnailImg: result,
                  };
                } catch (error) {
                  console.error(`Failed to fetch data for id ${item.id}:`, error);
                  return null;
                }
              })
            );
            setLdata(results.filter((result) => result !== null));
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        if (locationData.length > 0) {
          fetchData();
        }
      }, [locationData]);




    let lokdta = null;

    if (isLoading) {
        lokdta = <p>Loading...</p>;
    } else {
        lokdta = locationData
        .filter(item => item.country === 'International')
        .map((item, index) => (
            <Link to={`../${item.location_url}`} className="alllokcards" key={index}>
                <div className="imgallLok">
                {ldta
                    .filter((i) => i.location_id === item.id)
                    .flatMap((i) => i.thumbnailImg)
                    .flatMap((y) => y.ThumbnailImage)
                    .map((x, idx) => (
                      <img
                        key={idx} // Use index as key here
                        src={`https://backend.corporateeventplanner.in/storage/location-gallery/${x.location_thumbnail}`}
                        alt={item.location}
                      />
                    ))}
                </div>
                <div className="lokallhead">
                    <h3>{item.location}</h3>
                    <p>{item.small_description}</p>
                    {/* Use Link component properly */}
                    <Link to={`../${item.location_url}`}>View</Link>
                </div>
            </Link>
        ));
    }


  return (
    <>
    <MetaTag noindex={false} titles="Explore 10+ International Destinations From India For Your Next Corporate Offsite with CYJ Events" metakeywords="corporate event planner, corporate event venues, corporate offsite destinations, corporate offsite places, corporate offsite locations, corporate offsite venues, cyj events,best corporate event planner, top rated corporate event planner, best event planner, event planner" metaDescriptions="Book your next International Corporate Outing with CYJ Events! Call us at 8130781111 or 8826291111 to reserve the perfect venue for conferences, seminars, product launches, business meetings, and more. Take advantage of our exciting Corporate International Offsite packages and make your event truly exceptional." hrefLinks={window.location.href}/>
            <PageBanner image="image/image5.jpg" bannerheading="International" heading="International" pageLink="/international" />
            <section className="allLocationpageSection"
              
            >
                <div className="Heading">
                    <span className="topheading">All</span>
                    <div className="sbheadings" style={{left:'40px'}}>
                        <p style={{width:'150px'}}>Lorem ipsum dolor</p>
                        <h2>International</h2>
                        <p style={{width:'150px'}}>Lorem ipsum dolor</p>
                    </div>
                </div>
                <div className="allLocationpageBox">
                    {lokdta}
                </div>
            </section>
        </>
  )
}

export default International
