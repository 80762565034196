import React from 'react';
import PageBanner from '../../components/PageBanner';
import GalleryClient from './GalleryClient';
import MetaTag from '../../components/MetaTag';
const GalleryPage = () => {
  const isMobile = window.innerWidth <= 768;
  return (
    <div>
      <MetaTag noindex={false} titles="Our Portfolio - CYJ Events | Our Gallery " metakeywords="cyj events, corporate event planner, best corporate event planner, top rated  corporate event planner, best event planner in india, event planner in india, best corporate event planner in india" metaDescriptions="Our Portfolio - CYJ Events | Our Gallery" hrefLinks={window.location.href}/>
      <PageBanner image="image/image5.jpg" bannerheading="Our Portfolio" heading="Our Portfolio" pageLink="/our-portfolio" />
      <h2
  style={{
    textAlign: 'center',
    width: '85%',
    margin: 'auto',
    color: '#2E073F',
    fontSize: isMobile ? '28px' : '42px',
    textTransform: 'uppercase',
    marginBottom: isMobile ? '1rem':'-2rem',
    marginTop: '1rem',
  }}
>
  Our Recent Exposures
</h2>
      <GalleryClient />
    </div>
  )
}

export default GalleryPage
